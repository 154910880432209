<template>
<b-card v-if="users" no-body class="card-company-table">
    <table class="table b-table" v-if="users">

        <thead>
            <tr>
                <th scope="col" v-for="field in fields" :key="field.key">{{field.label}}</th>
            </tr>
        </thead>

        <draggable v-model="users" tag="tbody" handle=".handle">
            <tr v-for="(item,index) in users" :key="item.id">
                <td>
                    <feather-icon icon="MoveIcon" class="handle" />
                </td>
                <td>
                    <span class="font-weight-bolder mb-12">#{{index+1 }}</span>
                </td>
                <td>
                    <span class="font-weight-bolder mb-12">{{
                        item.createdAt | moment("DD.MM.YYYY hh:mm")
                    }}</span>
                </td>
                <td>
                    <b-badge pill :variant="item.isActive ? 'light-success' : 'light-danger'">
                        {{ item.isActive ? "AKTİF" : "PASİF" }}
                    </b-badge>
                </td>
                <td>
                    <span class="font-weight-bolder mb-12">{{item.code }}</span>
                </td>
                <td>
                    <span class="font-weight-bolder mb-12">{{item.name }}</span>
                </td>
                <td>
                    <b-dropdown class="mx-1" right text="İşlemler" variant="flat-primary">
                        <b-dropdown-item variant="flat-primary" :to="{ name: 'teacher-videogroup-page', params: { topicId: item.id } }">
                            <feather-icon icon="ArchiveIcon" class="mr-50" />
                            <span class="align-middle">Detaylar</span>
                        </b-dropdown-item>
                        <b-dropdown-item variant="flat-primary" @click="editTopic(item)" v-if="item.originalRef=='00000000-0000-0000-0000-000000000000'">
                            <feather-icon icon="EditIcon" class="mr-50" />
                            <span class="align-middle">Düzenle</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="deleteTopic(item.id)" v-if="item.originalRef=='00000000-0000-0000-0000-000000000000'">
                            <feather-icon icon="TrashIcon" class="mr-50" />
                            <span class="align-middle">Sil</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </td>
            </tr>
        </draggable>

    </table>

    <!--Open Add Topic-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="addTopicPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Yeni Konu Ekle</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Konu Kodu" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="addTopicRequest.code" />
                </b-form-group>
                <b-form-group label="Konu Adı" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="addTopicRequest.name" />
                </b-form-group>
                <b-form-group label="Konu Başarı Yüzdesi" label-for="name">
                    <b-form-input name="firstName" id="name" type="number" v-model="addTopicRequest.percent" />
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="addTopic">
                        Ekle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>

    </b-sidebar>
    <!--Open Edit Topic-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="editTopicPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Konu Düzenle</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Konu Kodu" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="editTopicRequest.code" />
                </b-form-group>
                <b-form-group label="Konu Adı" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="editTopicRequest.name" />
                </b-form-group>
                <b-form-group label="Konu Başarı Yüzdesi" label-for="name">
                    <b-form-input name="firstName" id="name" type="number" v-model="editTopicRequest.percent" />
                </b-form-group>
                <b-form-group label="Yayınlama" label-for="from">
                    <b-form-checkbox v-model="editTopicRequest.isActive">
                        Öğrencilere Yayınlansın
                    </b-form-checkbox>
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="updateTopic">
                        Güncelle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>

    </b-sidebar>
</b-card>
</template>

<script>
import {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BDropdown,
    BDropdownItem
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import draggable from 'vuedraggable'
export default {
    components: {
        draggable,
        ToastificationContent,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        BDropdown,
        BDropdownItem
    },
    data() {
        return {
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'createdAt', label: 'Kayıt Tarihi' },
                { key: 'isActive', label: 'Durum' },
                { key: 'code', label: 'Konu Kodu' },
                { key: 'name', label: 'Konu Adı' },
                { key: 'percent', label: 'Konu Başarı Yüzdesi' },
                { key: 'actions', label: 'İşlemler' },
            ],
            users: [],
            addTopicPanel: false,
            editTopicPanel: false,
            addTopicRequest: {
                childLessonId: 0,
                name: "",
                code: "",
                percent: 0
            },
            editTopicRequest: {
                topicId: 0,
                name: "",
                code: "",
                percent: 0,
                isActive: true
            },
        }
    },
    mounted() {
        this.$root.$on('AddButtonClick', () => {
            this.addTopicPanel = true;
        });
        this.$root.$on('PreviewSaveClick', async () => {
            var index = 0;
            var request = [];
            this.users.forEach(element => {
                element.viewOrder = index;
                request.push({
                    refId: element.id,
                    viewOrder: element.viewOrder
                });
                index++;
            });
            await this.$http.put("Teacher/TopicPreview", request);
        });
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            var users = await this.$http.get("Teacher/Topics/" + this.$route.params.childLessonId);
            this.users = users.data.data;
        },
        editTopic(data) {
            //console.log(data);
            this.editTopicRequest = {
                topicId: data.id,
                name: data.name,
                code: data.code,
                percent: data.percent,
                isActive: data.isActive
            }
            this.editTopicPanel = true;
        },
        addTopic() {
            this.addTopicRequest.childLessonId = this.$route.params.childLessonId;
            this.addTopicRequest.percent = parseInt(this.addTopicRequest.percent);
            var request = this.addTopicRequest;
            this.$http.post("Teacher/AddTopic", request).then((data) => {
                    this.getData();
                    this.addTopicPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Konu Oluşturuldu",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        updateTopic() {
            this.editTopicRequest.percent = parseInt(this.editTopicRequest.percent);
            var request = this.editTopicRequest;
            this.$http.put("Teacher/UpdateTopic", request).then((data) => {
                    this.getData();
                    this.editTopicPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Konu Güncellendi",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        deleteTopic(data) {
            this.$confirm({
                title: 'Sil',
                message: `Silmek İstediğinizden Emin misiniz?`,
                button: {
                    no: 'Vazgeç',
                    yes: 'Sil'
                },
                callback: confirm => {
                    if (confirm) {
                        this.$http.delete("Teacher/DeleteTopic/" + data).then(() => {
                                this.getData();
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'İşlem',
                                        icon: 'BellIcon',
                                        text: "İşlem Başarılı Konu Silindi",
                                        variant: 'success'
                                    },
                                });
                            })
                            .catch((error) => {
                                //console.log(error.response.data)
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Hata',
                                        icon: 'BellIcon',
                                        text: error.response.data.error.message,
                                        variant: 'danger'
                                    },
                                });
                            });
                    }
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
